<template>
  <div class="container" v-if="loading">
    <div class="choice-page page-breeding-egg">
      <div class="logo">
        <img src="@/assets/img/svg/logo.svg" alt="" />
      </div>
      <div class="page-image">
        <EggMystic />
      </div>
    </div>
  </div>
  <div class="page-breeding" v-else>
    <div class="page-breeding__top">
      <div class="page-breeding__title">
        {{ $t("breeding-title") }}
      </div>

      <div class="page-breeding__caption">
        {{ $t("breeding-caption") }}
      </div>
    </div>

    <div class="mates">
      <BreedingSelector
        :label="$t('breeding-first-char')"
        :id="1"
        :character-prop="character1"
        :neighbor-character="character2"
        @editCharacter="character1 = null"
      />
      <BreedingSelector
        :label="$t('breeding-second-char')"
        :id="2"
        :character-prop="character2"
        :neighbor-character="character1"
        @editCharacter="character2 = null"
      />
    </div>

    <div class="page-breeding__text">
      <div class="page-breeding__subtitle">
        {{ $t("breeding-subtitle") }}
      </div>

      <div class="default-text">
        {{ $t("breeding-notice") }}
      </div>
    </div>

    <MainButton
      :classes="!character1 || !character2 ? [] : ['game-btn']"
      @click="startBreeding"
      :text="$t('breeding-start')"
      :disabled="!character1 || !character2"
    />
  </div>
</template>

<script>
import MainButton from "../../components/common/buttons/MainButton";
import BreedingSelector from "@/components/breeding/BreedingSelector";
import SetProcessingMixin from "@/mixins/general/SetProcessingMixin";
import api from "@/api/api";
import MakeSignMixin from "@/mixins/sign/MakeSignMixin";
import successes from "@/utils/successes";
import { mapActions, mapGetters } from "vuex";
import errors from "../../utils/errors";
import EggMystic from "../../components/eggs/EggMystic";

export default {
  name: "Breeding",
  mixins: [SetProcessingMixin, MakeSignMixin],
  data: () => ({
    character1: null,
    character2: null,
    loading: false,
    race: null,
  }),
  computed: {
    ...mapGetters({
      getBalanceSub: "wallet/getBalanceSub",
    }),
    animalRace() {
      return this.race === "animals";
    },
    creatureRace() {
      return this.race === "creatures";
    },
    alienRace() {
      return this.race === "aliens";
    },
  },
  watch: {
    $route(val) {
      if (val.params.character) {
        this.character1 = val.params.character;
      }
    },
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    async startBreeding() {
      try {
        if (this.character1.id === this.character2.id) {
          this.setError(errors.CANT_BREED_WITH_ITSELF);
          return;
        }
        this.loading = true;
        let commission =
          this.character1.calculatedBreedingAmounts.subCommission +
          this.character2.calculatedBreedingAmounts.subCommission;
        if (this.character1.isCommunityCsh) {
          commission += this.character1.calculatedBreedingAmounts.ownerReward;
        }
        if (this.character2.isCommunityCsh) {
          commission += this.character2.calculatedBreedingAmounts.ownerReward;
        }
        if (commission > +this.getBalanceSub) {
          this.setError(errors.INSUFFICIENT_FUNDS);
          return;
        }
        let sig = await this.makeSign(commission);
        const response = await api.startBreeding({
          firstParentId: this.character1.id,
          secondParentId: this.character2.id,
          sig,
        });
        await this.loadBalance();
        this.setSuccess({
          message: successes.BREEDING_SUCCESS,
        });
        await this.$router.push({
          path: `cryptoshoppie/${response.data.id}`,
        });
      } catch (e) {
        console.error(e);
        this.setError({
          message: e.response?.data?.error?.message || e.message || e.msg,
        });
      } finally {
        this.character1 = null;
        this.character2 = null;
        this.loading = false;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.query.id === "1") {
        vm.character1 = to.params;
      }
      if (from.query.id === "2") {
        vm.character2 = to.params;
      }
    });
  },
  mounted() {
    if (this.$route.params.character) {
      this.character1 = this.$route.params.character;
    }
  },
  components: {
    EggMystic,
    BreedingSelector,
    MainButton,
  },
};
</script>

<style scoped></style>
