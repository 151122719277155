<template>
  <div class="mates-char">
    <div class="mates-char__wrap" v-if="!character">
      <router-link
        :to="`mates-list?id=${id}&neighborCharacter=${
          !!neighborCharacter ? neighborCharacter.id : ''
        }`"
        class="link-cover"
      />
      <RoundedButton>
        <template v-slot:icon>
          <PlusSvg />
        </template>
      </RoundedButton>

      <p class="mates-char__text">{{ label }}</p>
    </div>

    <div class="mates-char__wrap" v-else>
      <button class="mates-char__edit" @click="editCharacter">Edit</button>
      <div class="mates-char__img">
        <CharacterModel :image="characterImage" />
      </div>

      <div class="mates-char__name">{{ characterName }}</div>

      <div class="char-stats">
        <div class="char-stats__row">
          <div class="char-stats__title">
            {{ $t("char-stat-rarity") }}
          </div>

          <div class="char-stats__amount">75-100</div>
        </div>

        <div class="char-stats__row">
          <div class="char-stats__title">
            {{ $t("char-stat-sub") }}
          </div>

          <div class="char-stats__amount">
            {{ formatNumber(characterSubCommission) }}
          </div>
        </div>

        <div class="char-stats__row">
          <div class="char-stats__title">
            {{ $t("char-stat-igm") }}
          </div>

          <div class="char-stats__amount">
            {{ formatNumber(characterIgmCommission) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RoundedButton from "@/components/common/buttons/RoundedButton";
import PlusSvg from "@/components/icons/PlusSvg";
import CharacterModel from "@/components/character/СharacterModel";
import LoadShoppieByIdMixin from "@/mixins/loadShoppieByIdMixin";
import FormatNumberMixin from "@/mixins/common/FormatNumberMixin";
export default {
  name: "BreedingSelector",
  mixins: [LoadShoppieByIdMixin, FormatNumberMixin],
  props: {
    label: {
      type: String,
      required: false,
      default: () => "Select character",
    },
    id: {
      type: Number,
      required: false,
      default: () => 0,
    },
    neighborCharacter: {
      required: true,
      default: () => null,
    },
    characterProp: {
      required: false,
      default: () => null,
    },
  },
  watch: {
    characterProp(val) {
      this.character = val;
    },
  },
  methods: {
    editCharacter() {
      this.character = null;
      this.$emit("editCharacter");
    }
  },
  components: { CharacterModel, PlusSvg, RoundedButton },
};
</script>
